
































import Vue from 'vue';
import { required, minLength } from 'vuelidate/lib/validators';
import ChangePasswordRequest from '@/model/request/change-password-request';
import ModifyButton from '@/components/UserDetails/ModifyButton.vue';
import FormBase from '@/components/Form/Form.vue';
import PasswordField from '@/components/InputField/PasswordField.vue';

export default Vue.extend({
  name: 'PasswordChange',
  components: {
    ModifyButton,
    FormBase,
    PasswordField,
  },
  data() {
    return {
      submitted: false,
      loading: false,
      passwordForm: {
        currentPassword: '',
        isCurrentValid: false,
        newPassword: '',
        isNewValid: false,
      },
    };
  },

  computed: {
    title(): string {
      return this.$t('user-details.modify.password.title').toString();
    },
  },
  methods: {
    openDialog() {
      (this.$refs.dialog as any).open();
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
    },
    onInputCurrent(pass: string, valid: boolean) {
      this.passwordForm.currentPassword = pass;
      this.passwordForm.isCurrentValid = valid;
    },
    onInputNew(pass: string, valid: boolean) {
      this.passwordForm.newPassword = pass;
      this.passwordForm.isNewValid = valid;
    },
    resetModal() {
      this.submitted = false;
      this.passwordForm.currentPassword = '';
      this.passwordForm.newPassword = '';
      this.passwordForm.isCurrentValid = false;
      this.passwordForm.isNewValid = false;
      (this.$refs.currentPassword as any).reset();
      (this.$refs.newPassword as any).reset();
    },
    saveChange() {
      this.submitted = true;
      if (!this.passwordForm.isCurrentValid || !this.passwordForm.isNewValid) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch(
          'currentUserStorage/changePassword',
          new ChangePasswordRequest(this.passwordForm.currentPassword, this.passwordForm.newPassword),
        )
        .then((data: any) => {
          if (data) {
            this.closeDialog();
          }
        })
        .finally(() => (this.loading = false));
    },
  },
});
