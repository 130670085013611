var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopMenu'),_c('div',{staticClass:"page-wrapper"},[_c('HeaderBase',{scopedSlots:_vm._u([{key:"empty-column",fn:function(){return [_c('div',{staticClass:"user-card-column empty d-flex mx-0"})]},proxy:true},{key:"custom-fields",fn:function(){return [_c('div',{staticClass:"users-name"},[_vm._v(_vm._s(_vm.$t('user-details.name')))]),_c('div',{staticClass:"users-email"},[_vm._v(_vm._s(_vm.$t('user-details.email')))]),_c('div',{staticClass:"users-role"},[_vm._v(_vm._s(_vm.$t('user-details.roles')))])]},proxy:true},{key:"filter-column",fn:function(){return [_c('div',{staticClass:"filter empty-column d-flex mx-0"})]},proxy:true}])}),_c('div',{staticClass:"d-flex row m-0 pt-5"},[_c('div',{staticClass:"user-card-column"},[_c('DetailsCard'),_c('div',{staticClass:"d-flex justify-center"},[_c('EmailNotificationToggle',{attrs:{"value":_vm.currentUser.allowEmails,"label":_vm.$t(_vm.currentUser.allowEmails ? 'user-details.allow-emails' : 'user-details.disallow-emails')},on:{"change":_vm.turnNotifications}})],1)],1),_c('div',{staticClass:"user-list"},[_c('div',{staticClass:"users-header"},[_c('span',{staticClass:"users"},[_vm._v(_vm._s(_vm.$t('user-details.users').toUpperCase())+" ("+_vm._s(_vm.usersPageable.totalItems)+")")])]),_c('v-card',{staticClass:"users-table"},[_c('Table',{ref:"userTable",attrs:{"data":_vm.usersPageable,"fields":_vm.tableFields,"formattedColumns":['avatar', 'lastName', 'email', 'roles'],"isInfiniteScroll":""},on:{"newPageOption":_vm.pageOptionsChanged},scopedSlots:_vm._u([{key:"avatar",fn:function(ref){
var rowData = ref.rowData;
return [_c('UserAvatar',{staticClass:"d-flex justify-center",attrs:{"user":rowData,"isUserList":""}})]}},{key:"lastName",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.fullName(rowData)))])]}},{key:"email",fn:function(ref){
var rowData = ref.rowData;
return [_c('span',{staticClass:"email"},[_vm._v(_vm._s(rowData.email))])]}},{key:"roles",fn:function(ref){
var rowData = ref.rowData;
return [_c('Roles',{attrs:{"userId":rowData.id,"roles":rowData.roles,"isUserList":""}})]}}])}),_c('InfiniteScroll',{attrs:{"currentPage":_vm.usersPageable.currentPage,"totalPages":_vm.usersPageable.totalPages},on:{"infiniteLoad":_vm.pageOptionsChanged}})],1)],1),_c('div',{staticClass:"filter"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }