
















import Vue from 'vue';
import { acceptedPictureTypes } from '@/constants/accepted-file-types';
import File from '@/model/file';
import Notification from '@/model/notification';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
  name: 'UserImage',
  components: {
    LoadingIndicator,
  },
  props: {
    picture: Object as () => File,
  },
  data() {
    return {
      defaultImage: '/images/default-user-image.png',
      camera: '/images/camera-icon.svg',
      imageLoading: false,
    };
  },
  methods: {
    uploadPhoto(files: FileList) {
      if (!acceptedPictureTypes.includes(files[0].type)) {
        this.$store.dispatch('showErrorNotification', {
          message: this.$t('user-details.picture.file-type-not-allowed'),
        });
        return;
      }
      this.imageLoading = true;
      const formData = new FormData();
      formData.append('file', files[0]);
      this.$store.dispatch('currentUserStorage/uploadProfilePicture', formData).finally(() => {
        this.imageLoading = false;
      });
    },
    removePhoto() {
      this.$store
        .dispatch(
          'showConfirmNotification',
          new Notification(this.$t('user-details.picture.sure-to-delete').toString()),
        )
        .then((confirmed) => {
          if (confirmed) {
            this.imageLoading = true;
            this.$store.dispatch('currentUserStorage/deleteProfilePicture').finally(() => (this.imageLoading = false));
          }
        });
    },
  },
});
