














import Vue from 'vue';
import LoadingIndicator from '@/components/LoadingIndicator.vue';

export default Vue.extend({
  name: 'InfiniteScroll',
  components: {
    LoadingIndicator,
  },
  props: {
    currentPage: Number,
    totalPages: Number,
  },
  data() {
    return {
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 0],
      },
    };
  },
  methods: {
    onWaypoint({ going, direction }: any) {
      if (going === 'in' && (!direction || direction === 'top')) {
        this.$emit('infiniteLoad');
      }
    },
  },
});
