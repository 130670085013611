































import Vue from 'vue';
import UserImage from '@/components/UserDetails/UserImage.vue';
import Roles from '@/components/Roles.vue';
import { mapGetters } from 'vuex';
import NameChange from '@/components/UserDetails/Forms/NameChange.vue';
import EmailChange from '@/components/UserDetails/Forms/EmailChange.vue';
import PasswordChange from '@/components/UserDetails/Forms/PasswordChange.vue';

export default Vue.extend({
  name: 'DetailsCard',
  components: {
    UserImage,
    Roles,
    NameChange,
    EmailChange,
    PasswordChange,
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUserStorage/getCurrentUser' }),
  },
  data() {
    return {
      userIcon: '/images/user-icon.svg',
      envelopeIcon: '/images/envelope-icon.svg',
      loading: false,
    };
  },
});
