



































































import Vue from 'vue';
import TopMenu from '@/components/Menubar/TopMenu.vue';
import HeaderBase from '@/components/Header/HeaderBase.vue';
import DetailsCard from '@/components/UserDetails/DetailsCard.vue';
import EmailNotificationToggle from '@/components/InputField/Toggle.vue';
import Table from '@/components/Table/Table.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import Roles from '@/components/Roles.vue';
import PageOptions from '@/model/page/page-options';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'UserDetails',
  components: {
    TopMenu,
    HeaderBase,
    DetailsCard,
    EmailNotificationToggle,
    Table,
    UserAvatar,
    Roles,
    InfiniteScroll,
  },
  data() {
    return {
      pageOptions: new PageOptions(0, 15),
      tableFields: [
        {
          key: 'avatar',
          label: '',
          thClass: 'd-none',
          tdClass: 'avatar-column',
        },
        {
          key: 'lastName',
          label: '',
          thClass: 'd-none',
          tdClass: 'name-column',
        },
        {
          key: 'email',
          label: '',
          thClass: 'd-none',
          tdClass: 'email-column',
        },
        {
          key: 'roles',
          label: '',
          thClass: 'd-none',
          tdClass: 'role-column',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ usersPageable: 'userStorage/getUsers', currentUser: 'currentUserStorage/getCurrentUser' }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(): void {
      this.$store.dispatch('userStorage/getUsersPage', this.pageOptions);
    },
    pageOptionsChanged(): void {
      this.pageOptions.page++;
      this.fetch();
    },
    turnNotifications() {
      this.$store.dispatch('currentUserStorage/turnNotifications');
    },
  },
});
