


































import Vue from 'vue';
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import ChangeEmailRequest from '@/model/request/change-email-request';
import ModifyButton from '@/components/UserDetails/ModifyButton.vue';
import FormBase from '@/components/Form/Form.vue';

export default Vue.extend({
  name: 'EmailChange',
  components: {
    ModifyButton,
    FormBase,
  },
  data() {
    return {
      submitted: false,
      loading: false,
      emailForm: {
        newEmail: '',
        password: '',
      },
    };
  },
  validations: {
    emailForm: {
      newEmail: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  mounted() {
    this.emailForm.newEmail = this.currentUser.email;
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUserStorage/getCurrentUser' }),
    title(): string {
      return this.$t('user-details.modify.email.title').toString();
    },
    emailErrors(): string[] {
      const errors: string[] = [];
      if (this.submitted && !(this.$v.emailForm.newEmail as any).required) {
        errors.push(this.$t('user-details.modify.email.required').toString());
      } else if (this.submitted && !(this.$v.emailForm.newEmail as any).email) {
        errors.push(this.$t('user-details.modify.email.wrong-email-format').toString());
      }
      return errors;
    },
    emailPasswordErrors(): string {
      return this.submitted && !(this.$v.emailForm.password as any).required
        ? this.$t('user-details.modify.password.current-required').toString()
        : '';
    },
  },
  methods: {
    openDialog() {
      (this.$refs.dialog as any).open();
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
    },
    resetModal() {
      this.submitted = false;
      this.emailForm.newEmail = this.currentUser.email;
      this.emailForm.password = '';
    },
    saveChange() {
      this.submitted = true;
      this.$v.emailForm.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (this.emailForm.newEmail === this.currentUser.email) {
        this.$store.dispatch('showWarningNotification', { message: this.$t('user-details.modify.email.not-changed') });
      } else {
        this.loading = true;
        this.$store
          .dispatch(
            'currentUserStorage/changeEmail',
            new ChangeEmailRequest(this.emailForm.newEmail, this.emailForm.password),
          )
          .then((data: any) => {
            if (data) {
              this.closeDialog();
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
});
