

































import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import ChangeNameRequest from '@/model/request/change-name-request';
import ModifyButton from '@/components/UserDetails/ModifyButton.vue';
import FormBase from '@/components/Form/Form.vue';

export default Vue.extend({
  name: 'NameChange',
  components: {
    ModifyButton,
    FormBase,
  },
  data() {
    return {
      submitted: false,
      loading: false,
      nameForm: {
        firstName: '',
        lastName: '',
      },
    };
  },
  validations: {
    nameForm: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    },
  },
  mounted() {
    this.nameForm.firstName = this.currentUser.firstName;
    this.nameForm.lastName = this.currentUser.lastName;
  },
  computed: {
    ...mapGetters({ currentUser: 'currentUserStorage/getCurrentUser' }),
    title(): string {
      return this.$t('user-details.modify.name.title').toString();
    },
    lastNameErrors(): string {
      return this.submitted && !(this.$v.nameForm.lastName as any).required
        ? this.$t('user-details.modify.name.last-name-required').toString()
        : '';
    },
    firstNameErrors(): string {
      return this.submitted && !(this.$v.nameForm.firstName as any).required
        ? this.$t('user-details.modify.name.first-name-required').toString()
        : '';
    },
  },
  methods: {
    openDialog() {
      (this.$refs.dialog as any).open();
    },
    closeDialog() {
      (this.$refs.dialog as any).close();
    },
    resetModal() {
      this.submitted = false;
      this.nameForm.firstName = this.currentUser.firstName;
      this.nameForm.lastName = this.currentUser.lastName;
    },
    saveChange() {
      this.submitted = true;
      this.$v.nameForm.$touch();
      if (this.$v.$anyError) {
        return;
      }
      if (
        this.nameForm.firstName === this.currentUser.firstName &&
        this.nameForm.lastName === this.currentUser.lastName
      ) {
        this.$store.dispatch('showWarningNotification', { message: this.$t('user-details.modify.name.not-changed') });
      } else {
        this.loading = true;
        this.$store
          .dispatch(
            'currentUserStorage/changeName',
            new ChangeNameRequest(this.nameForm.firstName, this.nameForm.lastName),
          )
          .then((data: any) => {
            if (data) {
              this.closeDialog();
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
});
