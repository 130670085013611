


















import Vue from 'vue';
import { required, minLength } from 'vuelidate/lib/validators';
export default Vue.extend({
  name: 'PasswordField',
  props: {
    label: String,
    isLoading: Boolean,
    isSubmitted: { type: Boolean, default: false },
    isCurrent: { type: Boolean, default: false },
  },
  data() {
    return {
      isVisible: false,
      password: '',
    };
  },
  watch: {
    password() {
      this.$v.password.$touch();
      this.$emit('input', this.password, !this.$v.$anyError);
    },
  },
  validations() {
    if (this.isLogin || this.isCurrent) {
      return {
        password: {
          required,
          minLength: minLength(0),
          passwordChars() {
            return true;
          },
        },
      };
    } else {
      return {
        password: {
          required,
          minLength: minLength(8),
          passwordChars(password: string) {
            return (/[a-z]/.test(password) || /[A-Z]/.test(password)) && /[0-9]/.test(password);
          },
        },
      };
    }
  },
  computed: {
    isLogin(): boolean {
      return this.$route.path === this.$t('route-path.login').toString();
    },
    passwordErrors(): string[] {
      const errors: string[] = [];
      if (!this.isLogin && this.isSubmitted && !(this.$v.password as any).required) {
        errors.push(
          this.$t(
            this.isCurrent
              ? 'user-details.modify.password.current-required'
              : 'user-details.modify.password.new-required',
          ).toString(),
        );
      } else if (this.isLogin && this.isSubmitted && !(this.$v.password as any).required) {
        errors.push(this.$t('login.password-required').toString());
      } else if (!this.isCurrent && !this.isLogin && this.isSubmitted && !(this.$v.password as any).minLength) {
        errors.push(this.$t('user-details.modify.password.min-length').toString());
      } else if (!this.isCurrent && !this.isLogin && this.isSubmitted && !(this.$v.password as any).passwordChars) {
        errors.push(this.$t('user-details.modify.password.letter-number').toString());
      }
      return errors;
    },
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },
    reset() {
      this.isVisible = false;
      this.password = '';
    },
  },
});
