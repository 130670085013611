







import Vue from 'vue';

export default Vue.extend({
  name: 'ModifyButton',
  props: {
    icon: String,
    title: String,
  },
  methods: {
    openDialog() {
      this.$emit('click');
    },
  },
});
